import React, { useEffect, useRef, useState } from "react";
import { FaTimes, FaBars } from "react-icons/fa";
import { NavLink, useLocation } from "react-router-dom";
import artopia from "./artopia4.jpg";
import { supabase } from './supabaseClient';
import "./Header.scss";

const Header = () => {
  const [hasScrolled, setHasScrolled] = useState(false);
  const location = useLocation();
  const isHomePage = location.pathname === '/';
  const [dropdownItems, setDropdownItems] = useState([]);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [isMobileMenuVisible, setIsMobileMenuVisible] = useState(false);

  const dropdownRef = useRef(null);
  const toggleDropdown = () => {
    setIsDropdownVisible(!isDropdownVisible);
  };

  const fetchDropdownItems = async () => {
    let { data, error } = await supabase
      .from('sergi')
      .select('sergi_adi');
  
    if (error) {
      console.log('Error fetching data', error);
    } else {
      const uniqueItems = [...new Set(data.map(item => item.sergi_adi))];
      setDropdownItems(uniqueItems);
    }
  };

  console.log(dropdownItems);

  useEffect(() => {
    const handleScroll = () => {
      const showShadow = window.scrollY > 10;
      setHasScrolled(showShadow);

    };


    fetchDropdownItems();

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);

  }, []);

  return (
    <header className={`sticky top-0 z-30 py-3 header ${hasScrolled ? 'scrolled shadow-md rounded-es-full rounded-ee-full' : ''} ${isHomePage ? 'text-black' : 'text-black'}`}>
      <div className="container mx-auto flex justify-between items-center px-4 lg:px-20">
        <NavLink to="/" exact className="shrink-0">
          <img src={artopia} className="logo w-24 h-24 rounded-full" alt="Artopia Logo" />
        </NavLink>
  
        <div className="hidden md:flex justify-center flex-grow">
          <nav className="space-x-6 font-semibold text-lg">
            <NavLink to="/" exact className="header-link nav-link" activeClassName="text-indigo-600">
              Anasayfa
            </NavLink>
            <NavLink to="/sergiler" className="header-link nav-link" activeClassName="text-indigo-600">
              Galeri
            </NavLink>
            <NavLink to="/hakkimizda" className="header-link nav-link" activeClassName="text-indigo-600">
              Hakkımızda
            </NavLink>
            <NavLink to="/iletisim" className="header-link nav-link" activeClassName="text-indigo-600">
              İletişim
            </NavLink>
          </nav>
        </div>
  
        <div className="md:hidden ml-auto">
          <button onClick={() => setIsMobileMenuVisible(!isMobileMenuVisible)} className="text-3xl">
            {isMobileMenuVisible ? <FaTimes /> : <FaBars />}
          </button>
        </div>
  
        {isMobileMenuVisible && (
          <div className="absolute top-full left-0 w-full bg-white shadow-md py-5 px-4 flex flex-col items-center space-y-4 md:hidden">
            <NavLink to="/" exact className="header-link nav-link" activeClassName="text-indigo-600">
              Anasayfa
            </NavLink>
            <NavLink to="/sergiler" className="header-link nav-link" activeClassName="text-indigo-600">
              Galeri
            </NavLink>
            <NavLink to="/hakkimizda" className="header-link nav-link" activeClassName="text-indigo-600">
              Hakkımızda
            </NavLink>
            <NavLink to="/iletisim" className="header-link nav-link" activeClassName="text-indigo-600">
              İletişim
            </NavLink>
          </div>
        )}
      </div>
    </header>
  );
  
  }  

export default Header;

                {/* {isDropdownVisible && (
            <div className="absolute  mt-2 py-2 w-48 bg-white border border-gray-300 shadow-lg rounded-lg">
              {dropdownItems.map((item, index) => (
                <NavLink
                  key={index}
                  to={`/galeri/${item}`}
                  className="block text-black p-2 hover:bg-gray-100 bg-white"
                  onClick={toggleDropdown}
                >
                  {item}
                </NavLink>
              ))}
            </div>
              )} */}
