import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import { supabase } from './supabaseClient';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const SliderComponent = () => {
  const [artworksName, setArtworksName] = useState([]);

  useEffect(() => {
    const fetchArtworks = async () => {
      try {
        let { data, error } = await supabase.from('sergi_isimleri').select('*');
        if (error) {
          console.error('Error', error);
        } else {
          setArtworksName(data);
        }
      } catch (error) {
        console.error('Error', error);
      }
    };
    fetchArtworks();
  }, []);

  return (
    <div className="font-poppins">
      <Carousel
        autoPlay
        infiniteLoop
        showThumbs={true}
        showStatus={true}
        showArrows={true}
        interval={5000}
        className="carousel"
        emulateTouch
      >
        {artworksName.map((exhibition, index) => (
          <div key={index} className="relative h-screen">
            <img
              src={exhibition.resim}
              className="w-full h-full object-cover transition duration-700 ease-in-out hover:scale-105"
              alt={exhibition.baslik}
            />
            <div className="absolute inset-0 flex flex-col justify-center items-center text-center p-4 bg-black bg-opacity-40 transition duration-700 ease-in-out hover:bg-opacity-70">
              <h1 className="text-xl md:text-3xl lg:text-4xl font-semibold text-white mb-3 transition duration-700 ease-in-out hover:text-gray-300">
                {exhibition.aciklama}
              </h1>
              <p className="text-lg md:text-xl lg:text-2xl text-white mb-5 transition duration-700 ease-in-out">
                {exhibition.baslik}
              </p>
              <NavLink
                to={`/sergiler/${exhibition.constant_name}`}
                className="bg-gray-800 text-white font-bold py-2 px-4 md:py-3 md:px-8 rounded-lg hover:bg-gray-700 transition duration-300 ease-in-out transform hover:scale-110"
              >
                Keşfet
              </NavLink>
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default SliderComponent;
