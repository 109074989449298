import React from 'react';
import { FaPaintBrush, FaPalette, FaHeart, FaArrowRight } from 'react-icons/fa';
import './About.css'; // Ensure this file is correctly imported

const About = () => {
  return (
    <div className="bg-white text-gray-700 min-h-screen mt-20 py-20">
      <h1 className="text-4xl lg:text-5xl font-bold text-center text-gray-900 mb-12">Hakkımızda</h1>
      <div className="max-w-4xl mx-auto grid grid-cols-1 md:grid-cols-3 gap-10 px-4">
        <div className="flex flex-col items-center text-center space-y-4">
          <FaPaintBrush className="about-icon rotate text-6xl" style={{ color: "#667eea" }} />
          <p className="text-lg text-gray-400">
            Artopia'ya Hoş Geldiniz! Burası, sanatın, yaratıcılığın ve hayal gücünün buluştuğu yer. Dünya çapındaki sanatçıları ve sanat severleri bir araya getiriyoruz.
          </p>
        </div>
        <div className="flex flex-col items-center text-center space-y-4">
          <FaPalette className="about-icon bounce text-6xl" style={{ color: "#667eea" }} />
          <p className="text-lg text-gray-400">
            Farklı disiplinlerden gelen sanatçılar için bir vitrin sunuyoruz. Yaratıcı ruhlar burada eserlerini sergilemek ve ilham almak için bir araya geliyor.
          </p>
        </div>
        <div className="flex flex-col items-center text-center space-y-4">
          <FaHeart className="about-icon heartbeat text-6xl" style={{ color: "#667eea" }} />
          <p className="text-lg text-gray-400">
            Sanat, bizim için sadece görsel bir zevk değil, aynı zamanda kültürlerarası diyalog ve kişisel ifade için güçlü bir araçtır.
          </p>
        </div>
      </div>
      <p className="text-center text-gray-400 mt-10 py-5">
        Sanatla kalın,
        <br />
        Artopia Ekibi
      </p>
    </div>
  );
};

export default About;
