import React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import SliderComponent from './SliderComponent';
import HomePage from './HomePage';
import ArtworkGallery from './ArtworkGallery';
import About from './About';
import ContactForm from './ContactForm';
import Exhibition from './Exhibition';
const MainLayout = () => {
  const location = useLocation();

  const showSlider = location.pathname === '/'; 

  return (


      <div className={`relative z-10 ${showSlider ? '' : 'bg-white'}`}>
        <Header />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/hakkimizda" element={<About />} />
          <Route path="/iletisim" element={<ContactForm />} />
          <Route path="/sergiler" element={<Exhibition />} />
          <Route path="/sergiler/:sergi" element={<ArtworkGallery />} />
        </Routes>
        <Footer />
      </div>
  );
};

export default MainLayout;
