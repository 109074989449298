import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { supabase } from './supabaseClient';
import useDebounce from './useDebounce';
import './Loading.css';

const ArtworkGallery = () => {
  const [artworks, setArtworks] = useState([]);
  const [sortBy, setSortBy] = useState('asc');
  const [searchArtist, setSearchArtist] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const debouncedSearchTerm = useDebounce(searchArtist, 500);
  const { sergi } = useParams();

  useEffect(() => {
    const fetchArtworks = async () => {
      setIsLoading(true);
      try {
        let { data, error } = await supabase
          .from('sergi')
          .select('resim_sahibi, resimler, aciklama, boyutlar')
          .eq('sergi_adi', sergi);

        if (error) {
          console.error('Error', error);
        } else {
          const sortedData = data.sort((a, b) =>
            a.resim_sahibi.localeCompare(b.resim_sahibi, 'tr', { sensitivity: 'base' }) * (sortBy === 'asc' ? 1 : -1)
          );

          const filteredData = searchArtist
            ? sortedData.filter((artwork) =>
                artwork.resim_sahibi.toLowerCase().includes(searchArtist.toLowerCase())
              )
            : sortedData;

          setArtworks(filteredData);
        }
      } catch (error) {
        console.error('Error', error);
      }
      setIsLoading(false);
    };

    fetchArtworks();
  }, [sergi, sortBy, debouncedSearchTerm]);

  if (isLoading) {
    return <div className="loading-message container p-4 mt-20 text-center text-3xl ">Yükleniyor...</div>;
  }

  return (
    
    <div className="container mx-auto p-4 mt-20">
      <div className="mb-4 flex items-center justify-between bg-gray-100 p-4 rounded-lg">
        <div className="flex items-center space-x-2">
          <label className="text-gray-600 text-sm font-medium">Sırala:</label>
          <select
            onChange={(e) => setSortBy(e.target.value)}
            value={sortBy}
            className="border rounded-lg py-2 px-3 bg-white text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-indigo-600 transition duration-300"
          >
            <option value="asc">A-Z</option>
            <option value="desc">Z-A</option>
          </select>
        </div>
        <div className="relative flex items-center space-x-2">
          <label className="text-gray-600 text-sm font-medium">Ara:</label>
          <input
            type="text"
            placeholder="Sanatçı Arama.."
            value={searchArtist}
            onChange={(e) => setSearchArtist(e.target.value)}
            className="border rounded-lg py-2 px-3 bg-white text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-indigo-600 transition duration-300"
          />
          {searchArtist && (
            <button
              className="text-gray-500 hover:text-gray-700 focus:outline-none"
              onClick={() => setSearchArtist('')}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M10.293 9.293a1 1 0 011.414 0L15 12.586l1.293-1.293a1 1 0 111.414 1.414L16.414 14l1.293 1.293a1 1 0 01-1.414 1.414L15 15.414l-1.293 1.293a1 1 0 01-1.414-1.414L13.586 14l-1.293-1.293a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
                <path
                  fillRule="evenodd"
                  d="M5.293 5.293a1 1 0 011.414 0L10 8.586l1.293-1.293a1 1 0 111.414 1.414L11.414 10l1.293 1.293a1 1 0 01-1.414 1.414L10 11.414l-1.293 1.293a1 1 0 01-1.414-1.414L8.586 10 7.293 8.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          )}
        </div>
      </div>

      {artworks.map((artwork, index) => (
        <div key={index}>
          <div className="text-center mx-10 px-10 mt-10 mb-10">
            <h2 className="text-3xl font-bold text-gray-900 mb-4">{artwork.resim_sahibi}</h2>
            <div className="h-1 w-24 bg-indigo-600 mx-auto mb-4"></div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {artwork.resimler.map((resim, resimIndex) => (
              <div key={resimIndex} className="hover:shadow-xl transition duration-300 transform hover:scale-105">
                <div className="bg-white rounded-lg overflow-hidden">
                  <img className="w-full h-full object-cover" src={resim} alt={`${artwork.resim_sahibi} - ${artwork.aciklama[resimIndex]}`} />
                </div>
                <div className="p-4">
                  <p className="text-gray-700 text-3xl font-medium">{artwork.aciklama[resimIndex]}</p>
                  <p className="text-gray-600 text-2xl">Boyutlar: {artwork.boyutlar[resimIndex]}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ArtworkGallery;
