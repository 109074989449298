import React, { useState, useEffect, useRef } from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { supabase } from './supabaseClient';
import './hide-scroll.css'; // Ensure this CSS effectively hides the scrollbar across all browsers

const ArtworkSection = () => {
  const [randomArtworks, setRandomArtworks] = useState([]);
  const scrollContainer = useRef(null);

  useEffect(() => {
    const fetchRandomArtworks = async () => {
      try {
        let { data, error } = await supabase.from('sergi').select('*');
        if (error) {
          console.error('Error', error);
        } else {
          const shuffledData = data.sort(() => 0.5 - Math.random());
          setRandomArtworks(shuffledData);
        }
      } catch (error) {
        console.error('Error', error);
      }
    };
    fetchRandomArtworks();
  }, []);

  const scrollRight = () => {
    scrollContainer.current.scrollBy({ left: 300, behavior: 'smooth' });
  };

  const scrollLeft = () => {
    scrollContainer.current.scrollBy({ left: -300, behavior: 'smooth' });
  };

  return (
    <section className="px-4 sm:px-6 py-16 w-full overflow-hidden relative shadow-lg">
      <h2 className="text-xl sm:text-2xl md:text-3xl font-bold text-center mb-10">Beğenebileceğiniz Eserler</h2>
      <div ref={scrollContainer} className="flex overflow-x-auto space-x-4 sm:space-x-8 scrollbar-hide">
        <button onClick={scrollLeft} className="absolute left-0 top-1/2 transform -translate-y-1/2 z-10 bg-black text-white p-2 rounded-full shadow-lg hover:bg-gray-700 transition duration-300 ease-in-out">
          <FaChevronLeft />
        </button>
        <button onClick={scrollRight} className="absolute right-0 top-1/2 transform -translate-y-1/2 z-10 bg-black text-white p-2 rounded-full shadow-lg hover:bg-gray-700 transition duration-300 ease-in-out">
          <FaChevronRight />
        </button>
        {randomArtworks.map((artwork, index) => (
          <div key={index} className="flex-none w-full sm:w-[250px] h-auto bg-white rounded-lg overflow-hidden transform hover:scale-105 transition duration-300 ease-in-out">
            <img src={artwork.resimler[0]} alt={`${artwork.resim_sahibi} - ${artwork.aciklama[0]}`} className="mb-4 h-[250px] w-full object-cover rounded-t-lg" />
            <div className="p-4">
              <h3 className="text-lg font-bold">{artwork.resim_sahibi}</h3>
              <p className="text-gray-600">{artwork.aciklama[0]}</p>
              <p className="text-gray-600">{artwork.boyutlar[0]}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default ArtworkSection;
