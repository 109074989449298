import React from "react";
import { FaInstagram, FaHeart, FaEnvelope } from "react-icons/fa";

const Footer = () => {
  return (
    <footer className="bg-gray-300 text-black py-10">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col items-center">
          <div className="mb-4">
            <a href="/" className="text-2xl sm:text-3xl font-bold text-black">
              ARTOPIA
            </a>
          </div>
          <div className="mb-4 text-black text-center">
            Çankaya, Cinnah Cd. Apt.No:35 Daire No:4, 06690 Çankaya/Ankara
          </div>
          <div className="flex space-x-4 mb-4">
            <a href="mailto:artopiagaleri@gmail.com" className="hover:text-white transition-colors duration-300">
              <FaEnvelope className="text-4xl" />
            </a>
            <a href="https://instagram.com/artopiacollect" className="hover:text-white transition-colors duration-300">
              <FaInstagram className="text-4xl" />
            </a>
          </div>
          <div className="border-t border-gray-700 w-full pt-8 mt-8 text-center">
            <p className="text-black text-md">
              <span className="flex justify-center items-center mb-4">
                <FaHeart className="text-red-500" />
              </span> 
              © 2023 Artopia - Tüm Hakları Saklıdır.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
