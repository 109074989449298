import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import 'tailwindcss/tailwind.css';
import { supabase } from './supabaseClient';
import './Exhibition.css'; 

const ExhibitionCard = ({ title, imageUrl, dateRange, description, to }) => {
  const [isHovered, setIsHovered] = useState(false);

  const bgColorHover = 'text-gray-600'; 
  const textColorHover = 'text-black'; 
  const bgColor = 'bg-white'; 
  const textColor = 'text-gray-600'; 

  return (
    <div
      className={`exhibition-card relative rounded-lg overflow-hidden shadow-md hover:shadow-lg transition-transform transform-gpu hover:scale-105 hover:z-10 my-6 ${bgColor}`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <img className="w-full h-64 md:h-96 object-cover" src={imageUrl} alt={title} />
      <div className="p-4 md:p-6">
        <h2 className="text-xl md:text-2xl font-semibold mb-2 ${textColor}">{title}</h2>
        <p className="text-sm md:text-base mb-4 ${textColorHover}">Tarih: {dateRange}</p>
        <p className="text-base md:text-lg leading-relaxed mb-4 ${textColor}">{description}</p>
        <NavLink
          to={to}
          className={`inline-block font-bold py-2 px-4 rounded transition-colors duration-300 ${isHovered ? `${bgColorHover} ${textColorHover}` : `bg-black text-white`}`}
        >
          Sergiye Git
        </NavLink>
      </div>
    </div>
  );
};

const ExhibitionList = ({ exhibitions }) => {
  return (
    <div className="container mx-auto px-4 sm:px-6 lg:px-8">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {exhibitions.map((exhibition, index) => (
          <ExhibitionCard
            key={index}
            imageUrl={exhibition.resim}
            title={exhibition.baslik}
            dateRange={exhibition.tarih}
            description={exhibition.aciklama}
            to={exhibition.constant_name}
          />
        ))}
      </div>
    </div>
  );
};

const Exhibition = () => {
  const [artworksName, setArtworksName] = useState([]);
  useEffect(() => {
    const fetchArtworks = async () => {
      try {
        let { data, error } = await supabase.from('sergi_isimleri').select('*');
        if (error) {
          console.error('Error', error);
        } else {
          setArtworksName(data);
        }
      } catch (error) {
        console.error('Error', error);
      }
    };
    fetchArtworks();
  }, []);

  return (
    <main className="min-h-screen py-10 bg-gray-100">
      <ExhibitionList exhibitions={artworksName} />
    </main>
  );
};

export default Exhibition;
