import React, { useState } from 'react';
import Map from './Map';
import { FiMail, FiPhone, FiSend } from 'react-icons/fi';

const ContactForm = () => {
  const center = [39.89509, 32.85768];
  const zoom = 13;
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  });
  const [error, setError] = useState('');

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const isFormValid = () => {
    return formData.name && formData.email && formData.phone && formData.message;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isFormValid()) {
      setError('Lütfen tüm alanları doldurunuz.');
      return;
    }

    setError('');
    try {
      const response = await fetch('/api/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setFormData({
          name: '',
          email: '',
          phone: '',
          message: '',
        });
        alert('E-posta başarıyla gönderildi.');
      } else {
        setError('E-posta gönderilirken bir hata oluştu.');
      }
    } catch (error) {
      setError('E-posta gönderilirken bir hata oluştu.');
    }
  };

  return (
    <div className="container mx-auto px-4 mt-12 mb-20">
      <div className="flex flex-wrap -mx-2">
        <div className="w-full px-2 mb-6 lg:w-1/2 lg:mb-0">
          {error && (
            <p className="text-red-600 bg-red-100 border border-red-600 p-3 rounded-lg mb-4">
              {error}
            </p>
          )}
          <form onSubmit={handleSubmit} className="bg-white p-4 lg:p-6 rounded-lg shadow-lg">
            <div className="mb-4">
              <label htmlFor="name" className="block text-gray-800 text-sm font-semibold mb-2">
                Ad ve Soyad
              </label>
              <input
                type="text"
                name="name"
                id="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Adınızı ve soyadınızı girin"
                className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:ring-gray-300"
                required
              />
            </div>
            <div className="mb-4">
              <label htmlFor="email" className="block text-gray-800 text-sm font-semibold mb-2">
                Email Adresi
              </label>
              <input
                type="email"
                name="email"
                id="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="email@example.com"
                className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:ring-gray-300"
                required
              />
            </div>
            <div className="mb-4">
              <label htmlFor="phone" className="block text-gray-800 text-sm font-semibold mb-2">
                Telefon Numarası
              </label>
              <input
                type="tel"
                name="phone"
                id="phone"
                value={formData.phone}
                onChange={handleChange}
                placeholder="+90 ile başlayan telefon numaranızı girin"
                className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:ring-gray-300"
                required
              />
            </div>
            <div className="mb-4">
              <label htmlFor="message" className="block text-gray-800 text-sm font-semibold mb-2">
                Mesajınız
              </label>
              <textarea
                name="message"
                id="message"
                value={formData.message}
                onChange={handleChange}
                rows="4"
                placeholder="Mesajınızı buraya yazın"
                className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:ring-gray-300"
                required
              ></textarea>
            </div>
            <button
              type="submit"
              className="w-full bg-gray-800 text-white py-2 px-4 rounded-lg hover:bg-gray-700 transition duration-300 ease-in-out flex items-center justify-center"
            >
              Gönder <FiSend className="ml-2" />
            </button>
          </form>
        </div>
        <div className="w-full px-2 lg:w-1/2">
          <Map center={center} zoom={zoom} />
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
