// App.js
import React from 'react';
import "./App.css";
import MainLayout from './MainLayout';

function App() {
  return (
    <MainLayout />
  );
}

export default App;
