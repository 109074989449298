// HomePage.js
import React from 'react';
import ArtworkSection from './ArtworkSection';
import SliderComponent from './SliderComponent';

// Diğer bölümler için importlar (HeroSection, TestimonialsSection, vb.)

const HomePage = () => {
  return (
    <div>
      <SliderComponent />
      <ArtworkSection />

    </div>
  );
};

export default HomePage;
